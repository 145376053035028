import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux'
import { stopAnalyze } from '../../actions/AnalyzeActions'
import exit from '../icons/exitWhite.svg'


const AnalyzeButton = (props) => {

    const { end_watermark, end_footprint, watermark_info, footprint_info } = useSelector(state=>state.analyze_info)


    const [resultsActive, setResultsActive] = useState(true)
    const [activeData, setActiveData] = useState()

    useEffect(()=>{
      stopAnalyze()
    },[props.video])


    useEffect(()=>{
        if (watermark_info && watermark_info.wm_track){
            setActiveData(watermark_info.wm_track); 
        } else if (footprint_info && footprint_info.fp_track){
            setActiveData(footprint_info.fp_track); 
        }

    },[watermark_info, footprint_info])
    
    useEffect(()=>{
        setActiveData()
        setResultsActive(true)
    },[props.video])


    const handleScroll = (e) => {
        e.stopPropagation()
        console.log(props.analyzeRef)
        props.analyzeRef.current && props.analyzeRef.current.scrollIntoView({ behavior: "smooth" })
    }
    
    return (
        <div className='col-10 col-lg-4 d-flex justify-content-between pt-3'> 
            <div className = "col-3 position-relative d-flex align-items-center justify-content-center pe-2 pe-lg-0" >
                <div className='ring_container'>
                    <div className ={end_watermark && end_footprint ? 
                        "ring" :
                        "position-relative bg-red ring animate" } 
                        style={ end_watermark && end_footprint ? { borderTop:"transparent"}: {borderTop:"0.3vw solid #00C8D5"}}>
                    </div>
                    </div>
                    {
                    end_watermark && end_footprint ? 
                    <div 
                        role = "button"
                        id = "analyze-button"
                        onClick={(e)=>{e.stopPropagation(); props.handleRewind()}}
                        className = "d-flex align-items-center justify-content-center fw-bold position-absolute text-center bg-info text-primary border border-primary rounded-circle"
                    >ANALYZE</div> :
                    <div 
                        role = "button"
                        id = "analyze-button"
                        onClick={()=>stopAnalyze()}
                        className = "d-flex align-items-center justify-content-center fw-bold position-absolute text-center bg-info text-primary border border-primary rounded-circle"
                    >STOP</div> 
                    }
            </div>
            <div className='col-9'>
                {
                    activeData ? resultsActive ? 
                    <div className='w-100 d-flex align-items-start'
                        role = 'button'
                        onClick={(e)=>handleScroll(e)}
                    >
                        <div 
                            className='w-100 d-flex border rounded border-white p-1' 
                            style={{background:'#00060B4D'}}
                            onClick={(e)=>handleScroll(e)}
                        >
                            <img 
                                className='col-3'
                                src = {activeData.album.cover_url}  
                                // style={width:}
                                alt = '' />
                            <div className='col-9'>
                                <div 
                                    className="w-100 ps-2 fw-bold text-white text-nowrap overflow-hidden"
                                    style = {{textOverflow:'ellipsis'}}
                                    title = {activeData.track_title}
                                    >
                                    {activeData.track_title}
                                </div>
                                <div className='w-100 ps-2 pt-2 text-white'
                                    id = 'analyze-text'
                                    title = {activeData.members.map(data=>data.title).join()}
                                >
                                    {activeData.members.map(data=>data.title).join()}
                                </div>
                            </div>
                        </div>
                        <div className = 'ps-2'>
                            <img 
                                src  = {exit} 
                                role = 'button'
                                onClick={(e)=>{ e.stopPropagation(); setResultsActive(false)}}
                                alt = ''/>
                        </div>
                    </div> : '' : ''
                }
            </div>
        </div>
    );
}

export default AnalyzeButton;