import React, {useRef, useEffect, useState} from 'react'
import ReactPlayer from 'react-player/lazy'
import BannerSound from '../icons/BannerSound.svg'
import SoundOff from '../icons/SoundOff.svg'
import { getActiveVideos } from '../../actions/videoActions';
import { useSelector, useDispatch} from 'react-redux';


function Banner(props) {

    const bannerPlayerRef = useRef()

    const dispatch = useDispatch()

    const [muted, setMuted] = useState(true)

    const {activeVideo} = useSelector(state => state.activeVideo)  

    useEffect(()=>{
        dispatch(getActiveVideos(194))
    },[dispatch])

    return (
        <div id="banner_wrapper">
            {
                activeVideo &&

                <ReactPlayer
                    ref = {bannerPlayerRef} 
                    className='react-player shrinked'    
                    url = {activeVideo.scripted_bunny_path}
                    playing = {true}
                    muted = {muted}
                    config={{
                    file: { 
                        attributes: {
                        controlsList: 'nodownload noprops.fullscreen'
                        }
                    }
                    }}
                    width="100%"
                    height='100%'
                />  
            }
            {
                activeVideo && activeVideo.title ?
                <div className = 'col-12 px-5 pb-1 d-flex align-items-end position-absolute' style={{bottom:'15vw', zIndex:1000}}>
                    <div className='text-white ps-4 fw-light pe-4' style={{fontSize:'52px'}}>
                        {activeVideo.title.toUpperCase()}
                        <span>
                            {
                                muted ? 
                                <img 
                                    src = {SoundOff} 
                                    role = 'button'
                                    className='ps-4'
                                    onClick={()=>setMuted(false)}
                                    alt = ''/> :
                                <img 
                                    src = {BannerSound} 
                                    role = 'button'
                                    className='ps-4'
                                    onClick={()=>setMuted(true)}
                                    alt = ''/>
                            }

                        </span>
                    </div>
                </div> :""
            }

        </div>
    );
}

export default Banner;